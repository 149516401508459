<template>
    <div :class="$style['layout']">
        <slot
            :classes="$style['layout__top']"
            name="top"
        />
        <div
            :class="[
                $style['layout__inner'],
                routeNamespace === 'crm' && $style['layout__inner--shadow'],
            ]"
        >
            <slot />
        </div>
    </div>
</template>

<style lang="postcss" module>
.layout {
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    max-width: var(--wrap);

    &__top {
        display: grid;
        gap: 20px;
        padding: 20px;
    }

    &__inner {
        background-color: #fff;
        height: 100%;
        overflow: hidden;
        overflow-x: scroll;
        padding: 20px;

        @media (--medium) {
            overflow-x: auto;
        }

        &--shadow {
            box-shadow: var(--grey-300) 0 0 5px;
        }

        &--large-shadow {
            box-shadow: 0 8px 15px rgb(0 0 0 / 20%);
        }
    }

    @media (--x-large) {
        &__top {
            padding: 20px 60px;
        }

        &__inner {
            padding: 60px;
        }
    }
}
</style>
